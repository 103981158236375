import { Box, Stack, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { FeatureSection, LocalImage, Product, makeCurrencyFormatter } from "@akte-mord/common";
import { useTranslation } from "react-i18next";
import BackgroundImage from "../../public/homepage/product-case.jpg";
import Image from "next/image";
import Link from "next/link";
import React from "react";

export const ProductGrid: React.FC<{
  games: {
    title: string;
    sku: string;
    slug: string;
    // price: Product["prices"];
    image: LocalImage;
  }[];
}> = ({ games = [] }) => {
  const theme = useTheme();
  const isAtLeastXl = useMediaQuery(theme.breakpoints.up("xl"));
  const { t } = useTranslation("c-single-product");

  return (
    <FeatureSection
      altText="Cold Case Krimispiel"
      bgColor={theme.palette.primary.main}
      bgImage={BackgroundImage}
      bgPosition="top center"
      bgImageStyles={{ objectFit: "cover", objectPosition: "center center" }}
    >
      <Stack
        id="krimispiele-bestellen"
        component="ul"
        direction="row"
        spacing={{ xs: 2, sm: 6, md: 6, lg: 9, xl: 12 }}
        sx={{
          alignItems: "flex-start",
          maxWidth: { xs: "100%", sm: "66%" },
          mx: "auto",
          padding: 0,
          listStyleType: "none",
        }}
      >
        {games.map((c) => (
          <Box component="li" key={c.sku} sx={{ width: "50%" }}>
            <Stack>
              <Box>
                <ImageLink href={`/affaires/${c.slug}`}>
                  <Image
                    placeholder="blur"
                    {...c.image}
                    alt={c.title}
                    style={{ width: "100%", height: "auto" }}
                  />
                </ImageLink>
              </Box>
              <Box sx={{ mt: { xs: 3, sm: 3 } }}>
                <Typography
                  sx={{ pt: 0, pb: 0, textAlign: "center" }}
                  variant={isAtLeastXl ? "h3" : "h4"}
                >
                  {c.title}
                </Typography>
                <Box sx={{ pt: 1, textAlign: "center" }}>
                  <Typography component="span" sx={{ pt: 0, pb: 0 }} variant={isAtLeastXl ? "h5" : "h6"}>
                    24,00 €
                  </Typography>
                  <Typography variant="caption" component="span" sx={{ pl: 1, pt: 0, pb: 0 }}>
                    {t("includes-vat", "inkl. MwSt.")}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Box>
        ))}
      </Stack>
    </FeatureSection>
  );
};

export const ImageLink = styled(Link)(({ theme }) => ({
  display: "block",
  transition: theme.transitions.create("transform"),
  transform: "scale(1)",
  [theme.breakpoints.up("md")]: {
    "&:hover": {
      transform: "scale(1.025)",
    },
  },
}));
